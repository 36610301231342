@import "../../variables.scss";

@media (min-width: $bp-small) {
  .banner-presentation-container {
    position: relative;
    width: 100%;
    height: 400px;
    max-height: 350px;
    background-color: gray;
    margin-bottom: 75px;

    .image-container-banner-presentation {
      height: 100%;
      width: 100%;
    }

    .banner-image {
      height: 100%;
    }

    .banner-presentation-title-container {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 80%;

      height: 90px;
      text-align: center;

      background-color: rgba(0, 0, 0, 0.616);
      border: 3px solid aliceblue;

      position: absolute;

      left: calc(50% - 40%);
      bottom: -28px;
    }
    .banner-presentation-title {
      color: white;
      font-weight: 700;
      font-size: 35px;
      margin: 0;
    }
  }
}

@media (min-width: $bp-medium) {
  .banner-presentation-container {
    position: relative;
    width: 100%;
    height: 400px;
    max-height: 350px;
    background-color: gray;
    margin-bottom: 75px;

    .image-container-banner-presentation {
      height: 100%;
      width: 100%;
    }

    .banner-image {
      height: 100%;
    }

    .banner-presentation-title-container {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 400px;
      height: 90px;
      text-align: center;

      background-color: rgba(0, 0, 0, 0.616);
      border: 3px solid aliceblue;

      position: absolute;
      left: calc(50% - 200px);
      bottom: -28px;
    }
    .banner-presentation-title {
      color: white;
      font-weight: 700;
      font-size: 45px;
      margin: 0;
    }
  }
}
