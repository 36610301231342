.grey-button {
  background-color: #8A8478;
  color: white;
  padding: 8px 18px;
  border: none;
  font-weight: bold;
  margin: 20px 0px;
}

.default-button {
  cursor: pointer;
  width: 70%;
  height: 40px;
  font-size: 24px;
  font-weight: bold;
  background-color: #736a6a;
  border: none;
  border-radius: 10px;
}


