@import "../../../variables.scss";

@media screen and (min-width: $bp-small) {
  .grid-home-presentation-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;

    .item-container {
      height: 300px;
    }

    .image-grid-home {
      height: 100%;
      width: 100%;
    }

    .one {
      grid-column: 1;
      grid-row: 1;
    }

    .two {
      grid-column: 1;
      grid-row: 2;
    }

    .three {
      grid-column: 1;
      grid-row: 3;
    }

    .four {
      grid-column: 1;
      grid-row: 4;
    }

    .five {
      grid-column: 1;
      grid-row: 5;
    }

    .six {
      grid-column: 1;
      grid-row: 6;
    }
  }
}

@media (min-width: $bp-medium) {
  .grid-home-presentation-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    .item-container {
      height: 300px;
    }

    .one {
      grid-column: 1 / 2;
      grid-row: 1;
    }

    .two {
      grid-column: 2 / 3;
      grid-row: 1;
    }

    .three {
      grid-column: 3 / 3;
      grid-row: 1;
    }

    .four {
      grid-column: 1 / 2;
      grid-row: 2;
    }

    .five {
      grid-column: 2 / 3;
      grid-row: 2;
    }

    .six {
      grid-column: 3 / 3;
      grid-row: 2;
    }
  }

  
}
