.services-paragraph-title-container {
  display: flex;
  justify-content: space-between;

  .service-paragraph-title {
    margin: 0;
  }

  .service-paragraph-mini-line {
    margin: 0;
  }
}

.services-description-separator {
  border-bottom: 2px solid;
  margin: 20px 0px;
}

.services-paragraph-text {
  text-align: start;
}
