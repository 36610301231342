@import "../../variables.scss";

@media screen and (min-width: $bp-small) {
  .mobile-menu-burger {
    cursor: pointer;
    width: 40px;
    height: 100%;
    justify-self: end;
  }

  .mobile-menu-show {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: fixed;
    background: white;
    width: 100%;
    height: 100vh;
    align-items: center;
    padding: 0;
    top: 0;
    left: 0;

    .menu-navbar-text {
      color: black;
      font-weight: bold;
      font-family: "gadugi-bold";
    }
  }
}

@media screen and (min-width: $bp-medium) {
  .mobile-menu-burger {
    display: none;
    cursor: pointer;
    width: 40px;
    height: 100%;
    justify-self: end;
  }

  .mobile-menu-show {
    display: none;
    flex-direction: column;
    position: fixed;
    background: white;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0;
    top: 0;
    left: 0;
  }
}
