@import "../../../variables.scss";

@media screen and (min-width: $bp-small) {
  .carousel-section {
    .carousel-content-style {
      height: 80vh;
      min-height: 600px;
      color: #fff;
      line-height: 160px;
      text-align: center;
      background: #364d79;
    }

    .call-to-us-container {
      position: absolute;
      border: 4px solid gray;
      width: 80%;
      height: 200px;
      top: calc(50% - 100px);
      left: calc(50% - 40%);
      color: white;
      background: #0009;

      padding: 3%;

      h2 {
        color: white;
      }
    }
  }

  .scroll-icon {
    position: absolute;
    bottom: 0;
    left: calc( 100% - 77% );
    width: 60%  ;
  }
}

@media (min-width: $bp-medium) {
  .carousel-section {
    position: relative;

    .carousel-content-style {
      height: 80vh;
      min-height: 600px;
      color: #fff;
      line-height: 160px;
      text-align: center;
      background: #364d79;
    }

    .call-to-us-container {
      position: absolute;
      border: 4px solid gray;
      width: 600px;
      height: 200px;
      top: calc(50% - 100px);
      left: calc(50% - 300px);
      color: white;
      background: #0009;

      padding: 3%;

      h2 {
        color: white;
      }

      .button-relative {
        position: relative;
      }
    }
  }

  .scroll-icon {
    position: absolute;
    bottom: 0;
    left: calc( 100% - 57% );
    width: 15%  ;
  }
}

.carousel-content-style-1 {
  height: 80vh;
  min-height: 600px;
  width: 100%;
  display: inline-block;
  background: url("../../../../public/images/home1.jpg");
  background-size: cover;
  background-position-y: center;
}


