@import "../../variables.scss";

* {
  box-sizing: border-box;
}

@media (min-width: $bp-small) {
  .services-description-section {
    display: none;
  }

  .services-description-section-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 10%;
  }

  .services-paragraph-text {
    text-align: center;
  }

  .subtitle-container-services {
    padding: 0px 10%;
  }

}

@media (min-width: $bp-medium) {
  .services-description-section-mobile {
    display: none;
  }

  .services-description-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0px 10%;
  }

  .services-cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    .card-1 {
      grid-column: 1 / 3;
      grid-row: 1;

      height: fit-content;
      width: fit-content;
      justify-self: center;
    }
    .card-2 {
      grid-column: 3 / 5;
      grid-row: 1;

      height: fit-content;
      width: fit-content;
      justify-self: center;
    }
    .card-3 {
      grid-column: 5 / 7;
      grid-row: 1;

      height: fit-content;
      width: fit-content;
      justify-self: center;
    }
    .card-4 {
      grid-column: 2 / 4;
      grid-row: 2;

      height: fit-content;
      width: fit-content;
      justify-self: center;
    }
    .card-5 {
      grid-column: 4 / 6;
      grid-row: 2;

      height: fit-content;
      width: fit-content;
      justify-self: center;
    }
  }

  .overflow-box {
    padding: 20px;
    width: 100px;
    height: 100px;
    position: relative;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
  }

  .overflow-box::after {
    position: absolute;
    top: 5px;
    left: -7px;
    background: #000;
    width: 110px;
    height: 2px;
    content: "";
  }

  .overflow-box::before {
    position: absolute;
    bottom: 5px;
    left: -7px;
    background: #000;
    width: 110px;
    height: 2px;
    content: "";
  }
}
