@import '../../variables.scss';

@media (min-width: $bp-small){
  .card {
    display: grid;
    grid-template-columns: 20px 60% 20px;
    grid-template-rows: 20px auto 60px;
    justify-content: center;
    padding: 5% 0px;
  
    img {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      border-radius: 20px;
  
      margin: 10px;
      height: 200px;
      width: 200px;
    }
  
    .imaTwo{
      grid-column: 1 / 2;
      
      border-radius: 20px;
  
      margin: 10px;
      height: 200px;
      width: 200px;
    }
  
    .card-col-1 {
      grid-column: 1 / 2;
      grid-row: 1 / 4;
      border-right: 2px solid;
    }
  
    .card-col-3 {
      grid-column: 3 / 4;
      grid-row: 1 / 4;
      border-left: 2px solid;
    }
  
    .card-row-1 {
      grid-row: 1;
      grid-column: 1 / 4;
      border-bottom: 2px solid;
    }
  
    .card-row-3 {
      grid-row: 3;
      grid-column: 1 / 4;
      border-top: 2px solid;
    }
  }
}

.card {
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: 20px auto 60px;

  img {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    border-radius: 20px;

    margin: 10px;
    height: 200px;
    width: 200px;
  }

  .imaTwo{
    grid-column: 1 / 2;
    
    border-radius: 20px;

    margin: 10px;
    height: 200px;
    width: 200px;
  }

  .card-col-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    border-right: 2px solid;
  }

  .card-col-3 {
    grid-column: 3 / 4;
    grid-row: 1 / 4;
    border-left: 2px solid;
  }

  .card-row-1 {
    grid-row: 1;
    grid-column: 1 / 4;
    border-bottom: 2px solid;
  }

  .card-row-3 {
    grid-row: 3;
    grid-column: 1 / 4;
    border-top: 2px solid;
  }
}



