@import "~antd/dist/antd.css";
@import "./variables.scss";

@font-face {
  font-family: gadugi-gras;
  src: local("gadugi-gras"), url("./fonts/gadugi-gras.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: gadugi-bold;
  src: local("gadugi-bold"), url("./fonts/gadugi-bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "gadugi-normal";
  src: local("gadugi-normal"), url("./fonts/gadugi-normal.ttf");

  font-weight: 400;
}

body {
  margin: 0;
  font-family: gadugi-normal, gadugi-bold, gadugi-gras !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 22em) {
  h2 {
    font-size: 20px;
    line-height: 42pt;
    font-weight: 500;
    font-family: "gadugi-bold";
  }

  .normal-text,
  p {
    font-size: 16px;
    line-height: 18pt;
    font-weight: Light;
    font-family: "gadugi-normal";
  }
}

@media (min-width: 46.8em) {
  h1 {
    font-size: 33px;
    line-height: 42pt;
    font-weight: Bold;
    font-family: "gadugi-bold";
  }

  h2 {
    font-size: 28px;
    line-height: 42pt;
    font-weight: 500;
    font-family: "gadugi-bold";
  }

  h3 {
    font-size: 26px;
    line-height: 42pt;
    font-weight: normal;
    font-family: "gadugi-normal";
  }

  .slite-text {
    font-size: 24px;
    line-height: 18pt;
    font-weight: Light;
  }

  .normal-text,
  p {
    font-size: 18px;
    line-height: 18pt;
    font-weight: Light;
    font-family: "gadugi-normal";
  }

  .text-link {
    font-size: 18px;
    line-height: 18pt;
    font-weight: light;
    text-decoration: underline;
    font-family: "gadugi-normal";
  }

  .bullet-information {
    font-size: 16px;
    line-height: 17pt;
    font-weight: Light;
  }

  .menu-navbar-text {
    font-size: 15px;
    line-height: 16pt;
    font-weight: normal;
    color: White;
    font-family: "gadugi-bold";
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
