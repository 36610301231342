.card-long {
  display: grid;
  grid-template-columns: 20px 400px 20px;
  grid-template-rows: 20px auto auto 60px;
  height: 100%;

  .card-long-ima-one {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    border-radius: 20px;

    margin: 10px;
    height: 200px;

    width: 80%;
    justify-self: center;
  }

  .card-ima-two {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    border-radius: 20px;

    margin: 10px;
    height: 200px;
    width: 80%;
    justify-self: center;
  }

  .card-col-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 5;
    border-right: 2px solid;
  }

  .card-col-3 {
    grid-column: 3 / 4;
    grid-row: 1 / 5;
    border-left: 2px solid;
  }

  .card-row-1 {
    grid-row: 1;
    grid-column: 1 / 4;
    border-bottom: 2px solid;
  }

  .card-row-4 {
    grid-row: 4;
    grid-column: 1 / 4;
    border-top: 2px solid;
  }
}
