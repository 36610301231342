.banner-gallery-container {
  position: relative;
  width: 100%;
  height: auto;
  background-color: gray;
  margin-bottom: 75px;
  padding: 50px 0px;

  .gallery-banner {
    width: 100%;
  }
  .border-vertical-container {
    height: auto;
    border-top: 3px solid #bbb;
    border-bottom: 3px solid #bbb;
    display: flex;
    justify-content: center;

    .circle-container {
      width: 400px;
      background-color: #b9b9b9;
      border-radius: 50%;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .banner-presentation-title {
        color: white;
        font-weight: 700;
        font-size: 45px;
        margin: 0;
      }
    }
  }
}
