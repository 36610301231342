.gallery-photo-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;

  .photo-gallery {
    height: 300px;
overflow: hidden;

    .gallery-img{
      width: 100%;
      height: 100%;
    }
  }
}
