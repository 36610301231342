@import "../../../variables.scss";

@media (min-width: $bp-small) {
  .about-carousel-section {
    position: relative;

    .about-carousel-content-style {
      height: auto;
      min-height: 400px;

      color: #fff;
      line-height: 160px;
      text-align: center;
      background: white;

      display: grid !important;
      grid-template-columns: 100%;
      grid-template-rows: 1fr 1fr;
      column-gap: 50px;
      padding: 0px 10%;

      .about-carousel-one-container {
        display: grid;
        grid-template-columns: 20% 80%;
        grid-template-rows: 1fr 1fr;
        grid-column: 1;
        grid-row: 1;
        .one {
          grid-column: 1 / 2;
          grid-row: 1;
          position: relative;
          justify-content: center;
          display: flex;
          flex-direction: column;

          .vertical-title {
            transform: rotate(270deg);
            width: max-content;
            position: absolute;
            top: 50%;
            left: -130px;
          }
        }

        .two {
          grid-column: 2 / 3;
          grid-row: 1;

          width: 100%;
          height: 100%;
          justify-content: center;
          display: flex;
          flex-direction: column;

          .Image-carousel-content {
            height: auto;
            width: 100%;
          }
        }
      }

      .about-carousel-two-container {
        grid-column: 1;
        grid-row: 2;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
      .four {
        grid-column: 5 / 6;
      }

      .Image-carousel-content {
        border-radius: 32px;
        height: 350px;
        width: 350px;
      }

      .black-regular-text {
        color: black;
      }

      .vertical-title {
        transform: rotate(270deg);
        width: max-content;
        position: absolute;
        top: 50%;
        left: -50%;
      }
    }
  }
}

@media (min-width: $bp-medium) {
  .about-carousel-section {
    position: relative;

    .about-carousel-content-style {
      height: auto;
      min-height: 400px;

      color: #fff;
      line-height: 160px;
      text-align: center;
      background: white;

      display: grid !important;
      grid-template-columns: 40% 60%;
      grid-template-rows: 1fr;
      column-gap: 50px;
      padding: 0px 10%;
      .about-carousel-one-container {
        display: grid;
        grid-template-columns: 20% 80%;
        grid-template-rows: 1fr;
        grid-column: 1;
        grid-row: 1;
      }

      .about-carousel-two-container {
        grid-column: 2 / 4;
        grid-row: 1;
        justify-content: start;
        display: flex;
        flex-direction: column;
      }

      .one {
        grid-column: 1 / 2;
        grid-row: 1;
        position: relative;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }

      .two {
        grid-column: 2 / 3;
        grid-row: 1;

        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }

      .three {
        grid-column: 2 / 4;
        grid-row: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
      .four {
        grid-column: 5 / 6;
      }

      .Image-carousel-content {
        border-radius: 32px;
        height: 350px;
        width: 350px;
      }

      .black-regular-text {
        color: black;
      }

      .vertical-title {
        transform: rotate(270deg);
        width: max-content;
        position: absolute;
        top: 50%;
        left: -50%;
      }
    }
  }

  .about-carousel-carousel {
    height: 450px !important;
    .slick-dots {
      margin: 0 !important;
      height: 14px !important;
      li {
        height: auto;
        width: 100px;
        margin: 0;

        button {
          background-color: black !important;
          height: 12px !important;
          margin: 0 !important;
          width: 100px;
        }
      }
      li.slick-active {
        height: auto;
        width: 100px;
        margin: 0;

        button {
          background-color: #8a8478 !important;
        }
      }
    }
  }
}



.ant-carousel .slick-dots li.slick-active button{
  background-color: black !important;
}

.ant-carousel .slick-dots li button{
  background-color: grey !important;

}