@import "../../variables.scss";

@media screen and (min-width: $bp-small) {
  .navbarContainer {
    background-color: rgba(0, 0, 0, 0.616);
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 64px;
    width: 100%;
    padding: 0% 5%;

    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;

    .navbar-col-left-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .navbar-white-logo {
        width: 70px;
      }
    }

    .menu {
      color: white;
      list-style: none;
      display: none;
      justify-content: space-around;
      width: 600px;
      margin: 0;

      .link-menu {
        display: flex;
        align-items: center;
        font-family: "gadugi-bold";
        font-size: 15pt !important;

        &:hover {
          text-decoration: underline;
          color: white;
        }

        &:active &:focus {
          color: #cf8a56;
          text-decoration: underline !important;
        }
      }
    }
  }
}

@media screen and (min-width: $bp-medium) {
  .navbarContainer {
    background-color: rgba(0, 0, 0, 0.616);
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 80px;
    width: 100%;

    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    padding: 0% 11%;

    .navbar-col-left-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .navbar-white-logo {
        width: 110px;
      }
    }

    .menu {
      color: white;
      list-style: none;
      display: flex;
      justify-content: space-around;
      width: 600px;
      margin: 0;

      .link-menu {
        display: flex;
        align-items: center;
        font-family: "gadugi-bold";
        font-size: 15pt !important;

        &:hover {
          text-decoration: underline;
          color: white;
        }

        &:active &:focus {
          color: #8A8478;
          text-decoration: underline !important;
        }
      }
    }
  }
}
