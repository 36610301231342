@import "../../variables.scss";

@media screen and (min-width: $bp-small) {
  .about-description-container {
    padding: 0px 15%;
  }

  .about-list-container {
    padding: 0px 15%;
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto auto auto auto;

    .one {
      grid-column: 1 / 3;
      grid-row: 1;
      justify-self: flex-start;

      height: fit-content;
      width: 100%;
    }
    .two {
      grid-column: 1 / 3;
      grid-row: 5;
      justify-self: flex-start;

      height: fit-content;
      width: 100%;
    }
    .three {
      grid-column: 1 / 3;
      grid-row: 2;
      justify-self: flex-start;

      height: fit-content;
      width: 100%;
    }
    .four {
      grid-column: 1 / 3;
      grid-row: 6;
      justify-self: flex-start;

      height: fit-content;
      width: 100%;
    }
    .five {
      grid-column: 1 / 3;
      grid-row: 3;
      justify-self: flex-start;

      height: fit-content;
      width: 100%;
    }
    .six {
      grid-column: 1 / 3;
      grid-row: 7;
      justify-self: flex-start;

      height: fit-content;
      width: 100%;
    }
    .seven {
      grid-column: 1 / 3;
      grid-row: 4;
      justify-self: flex-start;

      height: fit-content;
      width: 100%;
    }
    .eight {
      grid-column: 1 / 3;
      grid-row: 8;
      justify-self: flex-start;

      height: fit-content;
      width: 100%;
    }

    .text-list-about {
      font-size: 18px;
      text-align: start;
    }
  }
}

@media screen and (min-width: $bp-medium) {
  .about-description-container {
    padding: 0px 30%;
  }

  .about-list-container {
    padding: 0px 20%;
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px 50px 50px 50px;

    .one {
      grid-column: 1 / 2;
      grid-row: 1;
      justify-self: flex-start;

      height: 200px;
      width: 100%;
      padding: 0px 0px 0px 20%;
    }
    .two {
      grid-column: 2 / 3;
      grid-row: 1;
      justify-self: flex-start;

      height: 200px;
      width: 100%;
      padding: 0px 0px 0px 20%;
    }
    .three {
      grid-column: 1 / 2;
      grid-row: 2;
      justify-self: flex-start;

      height: 200px;
      width: 100%;
      padding: 0px 0px 0px 20%;
    }
    .four {
      grid-column: 2 / 3;
      grid-row: 2;
      justify-self: flex-start;

      height: 200px;
      width: 100%;
      padding: 0px 0px 0px 20%;
    }
    .five {
      grid-column: 1 / 2;
      grid-row: 3;
      justify-self: flex-start;

      height: 200px;
      width: 100%;
      padding: 0px 0px 0px 20%;
    }
    .six {
      grid-column: 2 / 3;
      grid-row: 3;
      justify-self: flex-start;

      height: 200px;
      width: 100%;
      padding: 0px 0px 0px 20%;
    }
    .seven {
      grid-column: 1 / 2;
      grid-row: 4;
      justify-self: flex-start;

      height: 200px;
      width: 100%;
      padding: 0px 0px 0px 20%;
    }
    .eight {
      grid-column: 2 / 3;
      grid-row: 4;
      justify-self: flex-start;

      height: 200px;
      width: 100%;
      padding: 0px 0px 0px 20%;
    }
  }
}
