@import "../../variables.scss";

@media all and (min-width: $bp-small) {
  .footer-container {
    display: grid;
    grid-template-columns: auto;

    background-color: #545454;
    padding: 30px 0px;

    .white-logo-icon {
      width: 180px;
    }

    .nsw-logo{
      width: 150px;
    }

    h3,
    p {
      color: white;
    }
  }

  .sub-footer {
    background: black;
    color: white;
    margin: 0;
    padding: 3%;
  }
}

@media (min-width: $bp-medium) {
  .footer-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;

    background-color: #545454;
    padding: 30px 0px;

    h3,
    p {
      color: white;
    }
  }
  .sub-footer {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 60px;
    width: 100%;

    .sub-footer-text {
      color: white;
      margin: 0;
    }
  }
}
