@import "../../variables.scss";

p {
  margin: 0;
}

@media (min-width: $bp-small) {
  .contact-description-container {
    padding: 0px 15%;
  }

  .contact-info-container {
    display: grid;
    grid-template-rows: 0px 1fr 1fr 1fr 0px;
    

    .one {
      grid-row: 2/3;

      .text-adres-container {
        display: flex;
        flex-direction: column;
      }
    }
    .two {
      grid-row: 3/4;
    }
    .three {
      grid-row: 4/5;
    }

    .one,
    .two,
    .three {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px 0px;
      .icons-contact-us {
        width: 60px;
        height: 60px;
        margin-right: 15px;
      }
    }
  }

  .form-section-container {
    padding: 0px 5%;

    .form-items-container {
      display: grid;
      grid-template-columns: 50%;
      grid-template-rows: auto;
      justify-content: center;

      .myform-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50px;

        p {
          margin: 0;
        }
      }
    }

    .myform-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50px;
      margin: 16px 0px;

      p {
        margin: 0;
      }
    }

    .contact-input-form {
      border: 0px;
      border-bottom: 1px solid;
    }
  }

  .map-container {
    padding: 0px 5%;

    .mapCanvas {
      width: 100%;
      height: 500px;
      margin: 50px 0px;
    }
  }
}

@media (min-width: $bp-medium) {
  .contact-description-container {
    padding: 0px 15%;
  }

  .contact-info-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    margin: 30px 0px;

    .one {
      grid-column: 2/3;
      grid-row: 1;

      .text-adres-container {
        display: flex;
        flex-direction: column;
      }
    }
    .two {
      grid-column: 3/4;
      grid-row: 1;
    }
    .three {
      grid-column: 4/5;
      grid-row: 1;
    }

    .one,
    .two,
    .three {
      display: flex;
      align-items: center;
      justify-content: center;
      .icons-contact-us {
        width: 60px;
        height: 60px;
        margin-right: 15px;
      }
    }
  }

  .form-section-container {
    padding: 0px calc(100% - 70%);

    .form-items-container {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;

      .myform-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;

        p {
          margin: 0;
        }
      }
    }

    .myform-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;

      p {
        margin: 0;
      }
    }

    .contact-input-form {
      border: 0px;
      border-bottom: 1px solid;
    }
  }

  .map-container {
    padding: 0px 15%;

    .mapCanvas {
      width: 600px;
      height: 500px;
      margin: 50px 0px;
    }
  }
}
